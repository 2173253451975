import React from 'react';
import { useTranslation } from 'react-i18next';
import { globalStyles } from '../../../styles/globalStyles';
import { colors } from '../../../styles/colors';

const AreasOfFocus = () => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <section style={styles.focusAreasSection}>
      <h2 style={globalStyles.h2}>{t('areasOfFocus.sectionTitle')}</h2>
      <div style={styles.focusAreasGrid}>
        <div style={styles.focusCard}>
          <h3 style={styles.focusCardTitle}>{t('areasOfFocus.focus1Title')}</h3>
          <p style={styles.focusCardText}>{t('areasOfFocus.focus1Text')}</p>
        </div>
        <div style={styles.focusCard}>
          <h3 style={styles.focusCardTitle}>{t('areasOfFocus.focus2Title')}</h3>
          <p style={styles.focusCardText}>{t('areasOfFocus.focus2Text')}</p>
        </div>
        <div style={styles.focusCard}>
          <h3 style={styles.focusCardTitle}>{t('areasOfFocus.focus3Title')}</h3>
          <p style={styles.focusCardText}>{t('areasOfFocus.focus3Text')}</p>
        </div>
        <div style={styles.focusCard}>
          <h3 style={styles.focusCardTitle}>{t('areasOfFocus.focus4Title')}</h3>
          <p style={styles.focusCardText}>{t('areasOfFocus.focus4Text')}</p>
        </div>
      </div>
    </section>
  );
};

const styles = {  
  focusAreasSection: {
    padding: '4rem 2rem',
    backgroundColor: 'white',  // Lighter background for better contrast
  },
  focusAreasGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '2rem',
    maxWidth: '900px',
    margin: '0 auto',
  },
  focusCard: {
    backgroundColor: colors.lightPurple,  // Set the card background to white
    padding: '2.5rem',  // Slightly increased padding
    borderRadius: '1rem',
    textAlign: 'center',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',  // Shadow all around the element
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  focusCardTitle: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    fontFamily: "'Playfair Display', serif",
  },
  focusCardText: {
    fontSize: '1.25rem',
    fontFamily: "'Roboto', sans-serif",
  },
  focusCardHover: {
    transform: 'scale(1.05)', // Optional: Slightly scale the card on hover
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Optional: Darker shadow on hover
  },
};

export default AreasOfFocus;
