import React from 'react';
import { motion } from 'framer-motion';
import { globalStyles } from '../../../styles/globalStyles';
import { useTranslation } from 'react-i18next';

const Session = () => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <div style={styles.introContent}>
      <h2 style={globalStyles.h2}>{t('session.sectionTitle')}</h2>
      <div style={styles.contentWrapper}>
        <motion.p style={styles.textBlock} variants={textVariants}>
          {t('session.text1')}
        </motion.p>
        <motion.p style={styles.textBlock} variants={textVariants}>
          {t('session.text2')}
        </motion.p>
      </div>
    </div>
  );
};

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
};

const styles = {
  introContent: {
    width: '100%',
    backgroundColor: 'white',
    padding: '2rem'
  },
  sectionHeading: {
    fontSize: '3rem', // Increase size for larger headings
    textAlign: 'center',
    marginBottom: '2rem',
    fontFamily: "'Playfair Display', serif",
    color: '#333',
  },
  contentWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '2rem',
    maxWidth: '1000px',
    margin: '0 auto',
  },
  textBlock: {
    fontSize: '1.5rem', // Larger text for readability
    lineHeight: '2',
    fontFamily: "'Roboto', sans-serif",
    color: '#555',
  },
};

export default Session;
