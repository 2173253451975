import { useEffect } from "react";


import {
  HashRouter, Routes, Route,
} from 'react-router-dom';
import RootLayout from "./pages/Root";
import AboutMePage from "./pages/AboutMePage/AboutMePage";

import i18n from './i18n'; // You need to import this in App.js

const App = () => {


  useEffect(() => {
  }, []);


  return (
    <div style={styles.main}>
        <HashRouter> {/* Use HashRouter for it to work with github pages for deployment */}
          <Routes>
            <Route path="/" element={<RootLayout />}>  
              <Route path='/' element={<AboutMePage/>} />
            </Route>
          </Routes>
        </HashRouter>
    </div>
  );
};

const styles = {
  main: {
    backgroundColor: '#f0f0f0',
  },
};

export default App;