// colors.js or colors.ts
export const colors = {
    primary: '#3498db',
    secondary: '#2ecc71',
    accent: '#e74c3c',
    background: '#f4f4f4',
    text: '#333333',
    logoColor: '#B860E6',
    lightPurple: '#cf00ff12'
    // Add more color variables as needed
};