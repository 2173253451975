import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa'; // Import the email icon
import { colors } from '../../styles/colors';
import { sendEmail } from '../../api/emailAPI';

Modal.setAppElement('#root');

const EmailModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation(); // Initialize translation function

  // Yup Validation Schema
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required(t('emailModal.requiredField')),
    lastName: Yup.string().required(t('emailModal.requiredField')),
    email: Yup.string().email(t('emailModal.invalidEmail')).required(t('emailModal.requiredField')),
    phoneNumber: Yup.string().required(t('emailModal.requiredField')),
    message: Yup.string().required(t('emailModal.requiredField')),
  });

  const [modalDimensions, setModalDimensions] = useState({
    width: '90%',
    height: 'auto',
  });

  const [status, setStatus] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setModalDimensions({ width: '75%', height: '70%' });
      } else {
        setModalDimensions({
          width: '90%',
          height: '80%',
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (status && status.successMsg) {
      const timer = setTimeout(() => {
        setStatus(null); // Clear the success message after 2 seconds
      }, 2000);

      return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted or if status changes
    }
  }, [status]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
      selectedOption: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true);

      // Call sendEmail directly and handle the response
      const responseStatus = await sendEmail(values);
      setStatus(responseStatus);

      if (responseStatus.successMsg) {
        resetForm(); // Clear the form inputs after successful email submission
        setTimeout(() => {
          onRequestClose();
        }, 2000);
      }
      setSubmitting(false);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        ...modalStyles,
        content: {
          ...modalStyles.content,
          width: modalDimensions.width,
          height: modalDimensions.height
        }
      }}
      contentLabel={t('emailModal.title')}
    >
      <div style={styles.headerContainer}>
        <FaEnvelope style={styles.icon} />
        <span style={styles.emailText}>{t('emailModal.title')}</span>
      </div>      <form onSubmit={formik.handleSubmit} style={styles.formContainer}>
        <div style={styles.formGroup}>
          <label style={styles.modalLabel}>
            {t('emailModal.firstName')}:
            {formik.touched.firstName && formik.errors.firstName ? (
              <span style={styles.errorText}>{formik.errors.firstName}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.modalInput}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.modalLabel}>
            {t('emailModal.lastName')}:
            {formik.touched.lastName && formik.errors.lastName ? (
              <span style={styles.errorText}>{formik.errors.lastName}</span>
            ) : null}
          </label>
          <input
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.modalInput}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.modalLabel}>
            {t('emailModal.email')}:
            {formik.touched.email && formik.errors.email ? (
              <span style={styles.errorText}>{formik.errors.email}</span>
            ) : null}
          </label>
          <input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.modalInput}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.modalLabel}>
            {t('emailModal.phoneNumber')}:
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <span style={styles.errorText}>{formik.errors.phoneNumber}</span>
            ) : null}
          </label>
          <input
            type="tel"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={styles.modalInput}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.modalLabel}>
            {t('emailModal.message')}:
            {formik.touched.message && formik.errors.message ? (
              <span style={styles.errorText}>{formik.errors.message}</span>
            ) : null}
          </label>
          <textarea
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t('emailModal.messagePlaceholder')}
            style={styles.modalTextarea}
          />
        </div>
        <button type="submit" style={styles.modalButton}>{t('emailModal.sendButton')}</button>
        {status && status.successMsg && <p style={{ color: 'green' }}>{t('emailModal.successMessage')}</p>}
        {status && status.errorMsg && <p style={{ color: 'red' }}>{t('emailModal.errorMessage')}</p>}
      </form>
    </Modal>
  );
};

const styles = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  icon: {
    fontSize: '2rem',
    fill: colors.logoColor, // Apply your preferred purple color here
    marginRight: '0.5rem',
  },
  emailText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
    fontFamily: "'Roboto', sans-serif",
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1.5rem',
    fontFamily: "'Playfair Display', serif",
  },
  modalButton: {
    display: 'block',
    marginTop: '1rem',
    padding: '0.75rem 2.5rem',
    background: colors.logoColor,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '0.75rem',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '1.2rem',
    transition: 'background-color 0.3s',
    textAlign: 'center',
    width: '75%',
    margin: 'auto',
    boxSizing: 'border-box',
  },
  formGroup: {
    marginBottom: '1.5rem',
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  modalLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
    fontSize: '1.1rem',
    color: '#333',
    fontFamily: "'Roboto', sans-serif",
    width: '100%',
  },
  modalInput: {
    width: '100%',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    border: '1px solid #ddd',
    fontSize: '16px',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Roboto', sans-serif",
    // transition: 'border-color 0.3s, box-shadow 0.3s',
    boxSizing: 'border-box',
  },
  // modalTextarea: {
  //   width: '100%',
  //   height: '120px',
  //   padding: '0.5rem',
  //   borderRadius: '0.5rem',
  //   border: '1px solid #ddd',
  //   fontSize: '16px',
  //   // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  //   fontFamily: "'Roboto', sans-serif",
  //   // transition: 'border-color 0.3s, box-shadow 0.3s',
  //   boxSizing: 'border-box',
  //   margin: 'auto',
  // },
  modalTextarea: {
    width: '100%',
    height: '120px',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    border: '1px solid #ddd',
    fontSize: '16px',
    fontFamily: "'Roboto', sans-serif",
    boxSizing: 'border-box',
    margin: 'auto',
    lineHeight: '1.5', // Ensure enough space between lines
    minHeight: '60px', // Minimum height to ensure both lines of placeholder are visible
    resize: 'none', // Prevents manual resizing of the textarea
  },

  radioContainer: {
    marginBottom: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  radio: {
    marginRight: '0.75rem',
  },
  planLabel: {
    fontSize: '1rem',
    color: '#333',
    fontFamily: "'Roboto', sans-serif",
  },
  errorText: {
    color: 'red',
    fontSize: '0.875rem',
    marginLeft: '10px',
  },
};

const modalStyles = {
  overlay: {
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    borderRadius: '0.75rem',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    boxSizing: 'border-box',
  },
};

export default EmailModal;
