import { apiURL } from "./apiConstants";
import axios from 'axios';

export const sendEmail = async (body) => {
  // Destructure the fields from the body object
  const { firstName, lastName, email, phoneNumber, message } = body;

  // Construct the full URL using the base apiURL and the 'send-email' endpoint
//   const url = `${apiURL}/send-email`;
  const url = `${apiURL}/send-email-sendgrid`;


  try {
    // Make the POST request to the constructed URL with the provided body
    const response = await axios.post(url, { firstName, lastName, email, phoneNumber, message });

    // Handle the response
    if (response.data === 'Email sent successfully') {
      return { successMsg: 'Your email has been sent successfully!' };
    } else {
      return { errorMsg: 'Failed to send email. Please try again later.' };
    }
  } catch (error) {
    return { errorMsg: 'Error sending email. Please check your input and try again.' };
  }
};