import React, { useState } from 'react';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import MentorshipFeatures from './MentorshipFeatures/MentorshipFeatures';
import AreasOfFocus from './AreasOfFocus/AreasOfFocus';
import IntroSection from './IntroSection/IntroSection';
import EmailModal from '../../components/EmailModal/EmailModal';
import Session from './Session/Session';
import { globalStyles } from '../../styles/globalStyles';
import { colors } from '../../styles/colors';
import { useTranslation } from 'react-i18next';

const MainComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation(); // Access the translation function

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
          <div style={styles.bh}>B"H</div> {/* Add BH at the top */}

      <div style={styles.pageContainer}>
        <ImageCarousel />
        <IntroSection />
        <Session></Session>
        <MentorshipFeatures />
        <AreasOfFocus />

        <section style={styles.ctaSection}>
      <h2 style={globalStyles.h2}>{t('cta.sectionTitle')}</h2>
      <p style={styles.ctaText}>{t('cta.text')}</p>
      <button style={styles.ctaButton} onClick={openModal}>
        {t('cta.buttonText')}
      </button>
    </section>
      </div>
      <EmailModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </>
  );
};

const styles = {
  bh: {
    position: 'absolute',
    top: '1rem',
    right: '2.5rem',
    fontSize: '0.9rem',
    fontFamily: "'Roboto', sans-serif",
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    border: 'none', // Removing the border to align with dropdown style
  },
  
  
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: '#f0f0f0',
    boxSizing: 'border-box',
    width: '100%',
    margin: 'auto',
  },
  ctaSection: {
    padding: '4rem 2rem',
    backgroundColor: colors.lightPurple,
    textAlign: 'center',
  },
  ctaHeading: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
    fontFamily: "'Playfair Display', serif",
  },
  ctaText: {
    fontSize: '1.5rem',
    marginBottom: '2rem',
    fontFamily: "'Roboto', sans-serif",
  },
  ctaButton: {
    backgroundColor: colors.logoColor,
    color: 'white',
    padding: '1rem 2rem',
    fontSize: '1.25rem',
    border: 'none',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    fontFamily: "'Roboto', sans-serif",
    transition: 'background-color 0.3s',
  },
  '@media (min-width: 768px)': {
    pageContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
};

export default MainComponent;
