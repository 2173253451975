import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CircularImage = ({ src, alt, size }) => {
  const [aspectRatio, setAspectRatio] = useState(1); // Default to 1 (square)

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      setAspectRatio(aspectRatio);
    };
  }, [src]);

  const styles = {
    imageWrapper: {
      width: size,
      height: `calc(${size} / ${aspectRatio})`,
      borderRadius: '50%',
      overflow: 'hidden',
      border: '5px solid #fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      backgroundImage: `url(${src})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },
    altText: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      textAlign: 'center',
      opacity: 0,
    },
  };

  return (
    <div style={styles.imageWrapper} role="img" aria-label={alt}>
      <div style={styles.altText}>{alt}</div>
    </div>
  );
};

CircularImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default CircularImage;
