import React, { useState, useEffect } from 'react';
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';
import img4 from './img4.png';
import img5 from './img5.png';
import img6 from './img6.png';

import { useTranslation } from 'react-i18next';

const ImageCarousel = () => {
  const { t } = useTranslation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const images = [img1, img2, img3, img4, img5, img6];

  const quotes = [
    { text: t('quotes.quote1.text'), author: t('quotes.quote1.author'), duration: 4500 },
    { text: t('quotes.quote2.text'), author: t('quotes.quote2.author'), duration: 5500 },
    { text: t('quotes.quote3.text'), author: t('quotes.quote3.author'), duration: 4500 },
    { text: t('quotes.quote4.text'), author: t('quotes.quote4.author'), duration: 4500 },
    { text: t('quotes.quote5.text'), author: t('quotes.quote5.author'), duration: 4500 },
    { text: t('quotes.quote6.text'), author: t('quotes.quote6.author'), duration: 4500 }
  ];

  const maxLength = Math.min(images.length, quotes.length);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % maxLength);
    }, quotes[currentSlideIndex].duration); // Use the duration of the current slide

    return () => clearInterval(interval);
  }, [currentSlideIndex, maxLength, quotes]);

  return (
    <div style={{
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: isMobile ? '250px' : '500px',  // Adjust height for mobile
      backgroundColor: '#f1f1f1',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    }}>
      {images.slice(0, maxLength).map((image, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: index === currentSlideIndex ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            zIndex: index === currentSlideIndex ? 2 : 1,
          }}
        >
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            padding: '0 20px',
            width: isMobile ? '90%' : '80%', // Adjust width for mobile
          }}>
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: isMobile ? '10px' : '20px', // Adjust padding for mobile
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            }}>
              <p style={{
                fontSize: isMobile ? '16px' : '32px', // Adjust font size for mobile
                fontFamily: isMobile ? "serif" : "fantasy", // Switch font family based on screen size
                margin: 0,
              }}>
                {`"${quotes[index].text}"`}
              </p>
              <p style={{
                fontSize: isMobile ? '12px' : '20px', // Adjust font size for mobile
                fontFamily: "'Roboto', sans-serif",
                marginTop: '10px',
                fontStyle: 'italic',
              }}>
                {`- ${quotes[index].author}`}
              </p>
            </div>
          </div>
        </div>
      ))}
      <div style={{ textAlign: 'center', position: 'absolute', bottom: '15px', width: '100%', zIndex: 1000 }}>
        {images.slice(0, maxLength).map((_, index) => (
          <span
            key={index}
            onClick={() => setCurrentSlideIndex(index)}
            style={{
              cursor: 'pointer',
              height: '15px',
              width: '15px',
              margin: '0 2px',
              backgroundColor: index === currentSlideIndex ? '#717171' : '#bbb',
              borderRadius: '50%',
              display: 'inline-block',
              transition: 'background-color 0.6s ease',
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;