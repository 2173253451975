import React from 'react';
import CircularImage from '../../../components/CircularImage/CircularImage';
import profileImage from './carole.png';
import { motion } from 'framer-motion';
import { globalStyles } from '../../../styles/globalStyles';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';

const IntroSection = () => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <div style={styles.backgroundWrapper}>

    <motion.section style={styles.aboutMeSection} initial="hidden" animate="visible" variants={variants}>
      {/* Image and Introduction */}
      <div style={styles.heroContent}>
        <motion.div style={styles.imageWrapper} whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <CircularImage src={profileImage} alt={t('aboutMe.heroHeading')} size="12rem" />
        </motion.div>
        <div style={styles.textContainer}>
          <motion.h1 style={styles.heroHeading} variants={textVariants}>
            {t('aboutMe.heroHeading')}
          </motion.h1>
          <motion.p style={styles.tagline} variants={textVariants}>
            {t('aboutMe.tagline')}
          </motion.p>
          <motion.p style={styles.heroText} variants={textVariants}>
            <strong style={styles.boldText}>{t('aboutMe.heroText')}</strong>
          </motion.p>
        </div>
      </div>
      
      {/* My Journey Section */}
      <div style={styles.introContent}>
        <h2 style={globalStyles.h2}>{t('aboutMe.sectionHeading')}</h2>
        <div style={styles.contentWrapper}>
          <motion.p style={styles.textBlock} variants={textVariants}>
            {t('aboutMe.introText')} {/* Combined text */}
          </motion.p>
        </div>
      </div>
    </motion.section>
    </div>

  );
};

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1.5 } },
};

const styles = {
  backgroundWrapper: {
    backgroundColor: colors.lightPurple, // Apply the background color here
    padding: '4rem 0', // You can adjust padding if needed
  },
  aboutMeSection: {
    // backgroundColor: colors.lightPurple,
    padding: '4rem 2rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
    overflow: 'hidden',
  },
  heroContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '3rem',
    textAlign: 'left', 
    flexDirection: 'row', 
    width: '100%',
  },
  imageWrapper: {
    borderRadius: '50%',
    border: '5px solid #f3d1f4',
    padding: '0.5rem',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  textContainer: {
    flex: 1, 
    maxWidth: '600px',
  },
  heroHeading: {
    fontSize: '3.5rem', 
    marginBottom: '1rem',
    fontFamily: "'Playfair Display', serif",
    color: '#333',
  },
  tagline: {
    fontSize: '1.75rem',
    marginBottom: '1rem',
    color: '#666',
    fontStyle: 'italic',
  },
  heroText: {
    fontSize: '1.5rem',
    fontFamily: "'Roboto', sans-serif",
    color: '#555',
  },
  boldText: {
    fontWeight: '700',
    fontSize: '1.6rem',
    color: '#000',
  },
  introContent: {
    width: '100%',
  },
  sectionHeading: {
    fontSize: '3rem', 
    textAlign: 'center',
    marginBottom: '2rem',
    fontFamily: "'Playfair Display', serif",
    color: '#333',
  },
  contentWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '2rem',
    maxWidth: '1000px',
    margin: '0 auto',
  },
  textBlock: {
    fontSize: '1.5rem', 
    lineHeight: '2',
    fontFamily: "'Roboto', sans-serif",
    color: '#555',
    columnCount: 2,  // Split the content into 2 columns
    columnGap: '2rem',  // Add some space between the columns
    textAlign: 'justify',  // Justify text for better alignment
  },
};

export default IntroSection;