import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaPhone, FaEnvelopeOpenText, FaGlobe } from 'react-icons/fa';
import { globalStyles } from '../../../styles/globalStyles';
import { colors } from '../../../styles/colors';

const MentorshipFeatures = () => {
  const { t } = useTranslation(); // Initialize translation function
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dynamicStyles = {
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',  // Adjust columns based on screen size
      gap: '2rem',
      maxWidth: '1000px',
      margin: '0 auto',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={globalStyles.h2}>{t('mentorshipFeatures.sectionTitle')}</h2>
      <div style={dynamicStyles.featuresGrid}>
        <div style={styles.card}>
          <FaCheckCircle style={styles.icon} />
          <h3 style={styles.cardTitle}>{t('mentorshipFeatures.feature1Title')}</h3>
          <p style={styles.cardDescription}>{t('mentorshipFeatures.feature1Text')}</p>
        </div>
        <div style={styles.card}>
          <FaPhone style={styles.icon} />
          <h3 style={styles.cardTitle}>{t('mentorshipFeatures.feature2Title')}</h3>
          <p style={styles.cardDescription}>{t('mentorshipFeatures.feature2Text')}</p>
        </div>
        <div style={styles.card}>
          <FaEnvelopeOpenText style={styles.icon} />
          <h3 style={styles.cardTitle}>{t('mentorshipFeatures.feature3Title')}</h3>
          <p style={styles.cardDescription}>{t('mentorshipFeatures.feature3Text')}</p>
        </div>
        <div style={styles.card}>
          <FaGlobe style={styles.icon} />
          <h3 style={styles.cardTitle}>{t('mentorshipFeatures.feature4Title')}</h3>
          <p style={styles.cardDescription}>{t('mentorshipFeatures.feature4Text')}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '4rem 2rem',
    backgroundColor: '#f9f9f9',
  },
  heading: {
    textAlign: 'center',
    fontSize: '2.5rem',
    marginBottom: '3rem',
    color: '#333',
  },
  card: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '1rem',
    textAlign: 'center',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',  // Shadow all around the element
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  cardTitle: {
    fontSize: '1.5rem',
    margin: '1rem 0',
    fontWeight: 'bold',
    color: '#555',
  },
  cardDescription: {
    fontSize: '1.2rem',
    color: '#777',
  },
  icon: {
    fontSize: '2.5rem',
    color: '#4CAF50',
    fill: colors.logoColor,  
  },
  cardHover: {
    transform: 'scale(1.05)',
  },
};

export default MentorshipFeatures;