import React, { useState, useEffect, useRef } from 'react';

const CustomDropdown = ({ options, selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const selectedOptionRef = useRef(null);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        selectedOptionRef.current &&
        !selectedOptionRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div style={styles.dropdownContainer} ref={dropdownRef}>
      <div
        style={styles.selectedOption}
        onClick={() => setIsOpen(!isOpen)}
        ref={selectedOptionRef}
      >
        {selectedOption}
      </div>
      {isOpen && (
        <ul style={styles.dropdownMenu}>
          {options
            .filter(option => option !== selectedOption)  // Exclude the selected option
            .map((option, index) => (
              <li
                key={option}
                style={index === options.length - 2 ? { ...styles.optionItem, ...styles.optionItemLast } : styles.optionItem}  // Adjust for the last visible item
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '4.5rem',  // Compact width
  },
  selectedOption: {
    padding: '0.5rem 0.75rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    fontSize: '1.2rem',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23666\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'feather feather-chevron-down\'%3E%3Cpolyline points=\'6 9 12 15 18 9\'/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.5rem center',
    backgroundSize: '0.8rem',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '4px',
    zIndex: 1000,
    listStyleType: 'none',
    margin: '0.25rem 0 0 0',
    padding: 0,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  optionItem: {
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'background-color 0.2s',
    borderBottom: '1px solid #eee',
  },
  optionItemLast: {
    borderBottom: 'none',
  },
  optionItemHover: {
    backgroundColor: '#f1f1f1',
  },
};

export default CustomDropdown;
