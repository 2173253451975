import React, { useState } from "react";
import logo from "./logo.png"; 
import EmailModal from "../EmailModal/EmailModal";
import { useTranslation } from 'react-i18next';
import { FaInstagram } from "react-icons/fa";
import CustomDropdown from '../CustomDropdown/CustomDropdown';  // Import your custom dropdown component
import { colors } from "../../styles/colors";

const NavBar = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t, i18n } = useTranslation(); 


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div style={styles.navbarContainer}>
        <div style={styles.logoWrapper}></div>
        <div style={styles.rightSection}>
          <a
            href="https://www.instagram.com/cmyriamcoaching?igsh=dmdlbDdzdTRtOGx4&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconContainer}
          >
            <FaInstagram style={styles.icon} />
          </a>
          <button style={styles.ctaButton} onClick={openModal}>
            {t('navbar.getInTouch')}
          </button>
          <CustomDropdown
            options={['EN', 'FR', 'ES']}
            selectedOption={i18n.language.toUpperCase()}
            onChange={(lng) => changeLanguage(lng.toLowerCase())}
          />
        </div>
      </div>
      <EmailModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </>
  );
};

const styles = {
  navbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottom: '1px solid #ddd',
    padding: '1rem 2rem',
    width: '100%',
    flexWrap: 'nowrap', 
  },
  logoWrapper: {
    width: '60%', 
    height: '10rem',
    backgroundImage: `url(${logo})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    width: '80%', 
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#333',
    fontSize: '2.5rem',
    transition: 'color 0.3s ease',
    textDecoration: 'none',
  },
  ctaButton: {
    backgroundColor: colors.logoColor,
    color: 'white',
    padding: '0.75rem 1.5rem',
    fontSize: '1.25rem',
    border: 'none',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    fontFamily: "'Roboto', sans-serif",
    transition: 'background-color 0.3s',
    textAlign: 'center',
  },
};

export default NavBar;
